body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topmost {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.leftmost {
  text-align: center;
  vertical-align: middle;
  position: sticky;
  left: 0;
  z-index: 1;
}

.review {
  min-width: 13.25em;
  max-width: 13.25em;
  height: 39ex;
  font-family: 'Kosugi Maru', sans-serif;
}
